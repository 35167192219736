import { h } from 'preact'; /** @jsx h */
import { useState } from 'preact/hooks';

export const Input = (props) => {
  const {
    type,
    label,
    id,
    name,
    value,
    max,
    min,
    step,
    onChange,
    disabled,
    required
  } = props;

  const [newValue, setNewValue] = useState(value);

  const returnChange = (e) => {
    setNewValue(e.target.value);
    onChange(e);
  };

  return (
    <div className="mb3">
      <label htmlFor={name}>{label}</label>
      <input
        id={id}
        className="input"
        name={name}
        value={newValue}
        type={type}
        max={max}
        min={min}
        step={step}
        onChange={returnChange}
        disabled={disabled}
        required={required}
      />
    </div>
  );
};

export const Select = (props) => {
  const { className, label, name, value, hasEmptyVal, onChange, optArray } =
    props;
  return (
    <div className={`mb3 ${className}`}>
      <label htmlFor={name}>{label}</label>
      <select
        className="input"
        name={name}
        value={value}
        onChange={onChange}
        required
      >
        {hasEmptyVal && <option value="">Alle</option>}
        {optArray.map((opt, index) => {
          return (
            <option key={index} value={opt.value}>
              {opt.tekst} {opt.antall ? `(ant. ${opt.antall})` : ''}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export const Textarea = (props) => {
  const { label, name, rows, onChange } = props;

  return (
    <div className="mb3">
      <label htmlFor={name}>{label}</label>
      <textarea
        className="w-100"
        rows={rows}
        name={name}
        id={name}
        maxLength="100"
        placeholder="Forklar endringen..."
        onChange={onChange}
        required
      ></textarea>
    </div>
  );
};

export const Error = (props) => {
  const { tekst } = props;

  return <p className="primary">{tekst}</p>;
};
