import { h } from 'preact'; /** @jsx h */

import { addLocale, locale } from 'primereact/api';

import { Calendar } from 'primereact/calendar';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';

import { FORMATS } from '../../utils/formatDates';

import './DatePicker.css';

locale('no');
addLocale('no', {
  firstDayOfWeek: FORMATS.FIRSTDAYOFWEEK,
  dayNames: FORMATS.DAYNAMES,
  dayNamesShort: FORMATS.DAYNAMESSHORT,
  dayNamesMin: FORMATS.DAYNAMESMIN,
  monthNames: FORMATS.MONTHNAMES,
  monthNamesShort: FORMATS.MONTHNAMESSHORT,
  today: FORMATS.TODAY,
  clear: FORMATS.CLEAR
});

const DatePicker = ({ className, date, setDate, ...props }) => {
  const handleDateChange = (e) => {
    setDate(e.value);
  };

  return (
    <div className={`w-full flex flex-col md:flex-row ${className}`}>
      <Calendar
        className={`${props.disabled ? '!cursor-not-allowed' : 'cursor-pointer'}`}
        value={new Date(date)}
        onChange={handleDateChange}
        locale="no"
        {...props}
      />
    </div>
  );
};

export default DatePicker;
