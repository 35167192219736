import { h } from 'preact'; /** @jsx h */

import { XMarkIcon } from '@heroicons/react/24/outline';
import { Title } from '@tremor/react';

const Modal = ({
  isOpen,
  onClose,
  onCancel,
  onConfirm,
  title,
  text,
  confirmLabel,
  cancelLabel
}) => {
  if (!isOpen) return null;

  return (
    <div
      className="backdrop absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      onClick={onClose}
    >
      <div className="relative bg-white p-6 rounded-tremor-default w-full mx-4 md:min-w-[500px] md:max-w-[500px]">
        <span
          className="absolute top-2 right-2 cursor-pointer transition-all hover:transform hover:scale-110"
          onClick={onClose}
        >
          <XMarkIcon className="h-5 w-5" />
        </span>
        <Title className="mb-2">{title}</Title>
        <p className="text-sm ">{text}</p>
        <div className="mt-4 flex justify-end">
          <button
            className="mr-4 px-4 py-2 rounded-tremor-default  border border-solid border-transparent bg-transparent text-secondary text-center   hover:border-secondary transition-all duration-300 ease-in-out"
            onClick={onCancel}
          >
            {cancelLabel || 'Avbryt'}
          </button>
          <button
            className="px-4 py-2 rounded-tremor-default  border border-solid border-secondary bg-secondary text-white text-center hover:bg-white hover:text-secondary hover:border-secondary transition-all duration-300 ease-in-out"
            onClick={onConfirm}
          >
            {confirmLabel || 'OK'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
