import { h, Fragment } from 'preact'; /** @jsx h */
import { Input } from '../components/input';
import { datoToText } from '../components/utils';

const RedigerFravaerDager = ({
  fravaersDager,
  fjernDato,
  onChangeEndre,
  fjernDisabled,
  type
}) => {
  const datoVarsel = {
    1: 'Dato er sendt til regnskap, kan ikke endres',
    2: 'En annen syke- eller egenmelding er registrert denne datoen',
    3: 'Vanlig arbeidstimer er registrert denne datoen'
  };
  return (
    <table className="pa3 w-100 td-l1 mb3-halv" id="fravaermelding-liste">
      <thead className="bt b--black">
        <tr className="tr">
          <th>Dato</th>
          <th>Aktiv arbeidstid</th>
          <th>Passiv reisetid</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(fravaersDager).map((dato, index) => {
          return (
            <tr key={index} className="tr">
              <td>{datoToText(dato)}</td>
              <Fragment key={index}>
                {fravaersDager[dato].status ? (
                  <Fragment>
                    <td key={index} className="primary">
                      {datoVarsel[fravaersDager[dato].status]}
                    </td>
                    <td></td>
                  </Fragment>
                ) : (
                  <Fragment>
                    <td>
                      <Input
                        type="number"
                        name={fravaersDager[dato].aktiv}
                        value={parseFloat(fravaersDager[dato].aktiv)}
                        onChange={(e) => onChangeEndre(e, 'aktiv', dato)}
                        step={0.25}
                        min={0}
                        required={true}
                      ></Input>
                    </td>
                    <td>
                      <Input
                        type="number"
                        name={fravaersDager[dato].passiv}
                        value={parseFloat(fravaersDager[dato].passiv)}
                        onChange={(e) => onChangeEndre(e, 'passiv', dato)}
                        step={0.25}
                        min={0}
                        required={true}
                      ></Input>
                    </td>
                  </Fragment>
                )}
              </Fragment>
              {type === 'S' && (
                <button
                  onClick={(e) => fjernDato(dato, e)}
                  disabled={fjernDisabled || fravaersDager[dato].status === 1}
                  type="button"
                >
                  Fjern
                </button>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default RedigerFravaerDager;
