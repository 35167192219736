import { h } from 'preact'; /** @jsx h */
import { datoToText } from './utils';

const VisFravaerTabell = ({ person }) => {
  return (
    <table className="pa3 w-100 td-l1 mb3-halv">
      <thead className="bt b--black">
        <th>Dato</th>
        <th>Aktiv arbeidstid</th>
        <th>Passiv reisetid</th>
      </thead>

      <tbody>
        {Object.keys(person.timer).map((item, index) => {
          return (
            <tr key={index} className="tr">
              <td>{datoToText(item)}</td>
              <td>{parseFloat(person.timer[item].aktiv)}</td>
              <td>{parseFloat(person.timer[item].passiv)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default VisFravaerTabell;
