import { h } from 'preact'; /** @jsx h */
import { useState } from 'preact/hooks';

const FraManedForm = ({ startFra, min, max, onSubmit }) => {
  const [fra, setFra] = useState(startFra);

  const onSubmitForm = (e) => {
    e.preventDefault();

    onSubmit({ fra });
  };

  const id = 'sykemelding_mnd';

  return (
    <form className="flex" onSubmit={onSubmitForm}>
      <div className="mb3">
        <label htmlFor={id}>Velg måned</label>
        <input
          id={id}
          className="input"
          name="fra-maned"
          value={fra}
          type="month"
          required={true}
          onInput={(e) => setFra(e.target.value)}
          min={min}
          max={max}
        />
      </div>
      <button className="mt3 mb3 ml3 pa2 ph3 bg-primary white db" value="Søk">
        Søk
      </button>
    </form>
  );
};

export default FraManedForm;
