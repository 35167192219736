import { h } from 'preact'; /** @jsx h */
import { useState } from 'preact/hooks';

const Input = ({ name, id, label, value, type, required, onInput }) => (
  <div className="mb3">
    <label htmlFor={id}>{label}</label>
    <input
      id={id}
      className="input"
      name={name}
      value={value}
      type={type}
      required={required}
      onInput={onInput}
    />
  </div>
);

const FraOgTilDatoForm = ({ onSubmit }) => {
  const [fra, setFra] = useState('');
  const [til, setTil] = useState('');

  const onSubmitForm = (e) => {
    e.preventDefault();

    onSubmit({ fra, til });
  };

  return (
    <form className="flex" onSubmit={onSubmitForm}>
      <Input
        id="sykemelding_fra_hist"
        type="date"
        label="Fra"
        value={fra}
        required={true}
        onInput={(e) => setFra(e.target.value)}
      />
      <Input
        id="sykemelding_til_hist"
        type="date"
        label="Til"
        value={til}
        required={true}
        onInput={(e) => setTil(e.target.value)}
      />
      <button className="mt3 mb3 ml3 pa2 ph3 bg-primary white db" value="Søk">
        Søk
      </button>
    </form>
  );
};

export default FraOgTilDatoForm;
