import { h } from 'preact'; /** @jsx h */
import { useState } from 'preact/hooks';

import { addLocale, locale } from 'primereact/api';

import { Calendar } from 'primereact/calendar';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';

import { Select, SelectItem } from '@tremor/react';
import { FORMATS } from '../../utils/formatDates';

import './DateRangePicker.css';

locale('no');
addLocale('no', {
  firstDayOfWeek: FORMATS.FIRSTDAYOFWEEK,
  dayNames: FORMATS.DAYNAMES,
  dayNamesShort: FORMATS.DAYNAMESSHORT,
  dayNamesMin: FORMATS.DAYNAMESMIN,
  monthNames: FORMATS.MONTHNAMES,
  monthNamesShort: FORMATS.MONTHNAMESSHORT,
  today: FORMATS.TODAY,
  clear: FORMATS.CLEAR
});

const DateRangePicker = ({
  dateRange,
  setDateRange,
  setOpenRow = false,
  presets = true,
  minDate = new Date('2020', '0', '1'),
  maxDate = new Date(),
  ...props
}) => {
  const [selectedOption, setSelectedOption] = useState('lastTwelveMonths');

  const handleDateChange = (e) => {
    setDateRange(e.value);
    setSelectedOption(''); // Reset the selected option when date range changes
    if (setOpenRow) setOpenRow(null); // Reset the view when date range changes
  };

  const handleSelectChange = (newValue) => {
    setSelectedOption(newValue);
    switch (newValue) {
      case 'lastOneMonth':
        setDateRange(lastOneMonth);
        break;
      case 'lastThreeMonths':
        setDateRange(lastThreeMonths);
        break;
      case 'lastSixMonths':
        setDateRange(lastSixMonths);
        break;
      case 'lastTwelveMonths':
        setDateRange(lastTwelveMonths);
        break;
      case 'thisYear':
        setDateRange(thisYear);
        break;
      case 'lastYear':
        setDateRange(lastYear);
        break;
      default:
        break;
    }
    if (setOpenRow) setOpenRow(null); // Reset the view when date range changes
  };

  const lastOneMonth = [
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 30
    ),
    new Date()
  ];

  const lastThreeMonths = [
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 90
    ),
    new Date()
  ];

  const lastSixMonths = [
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 180
    ),
    new Date()
  ];

  const lastTwelveMonths = [
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 365
    ),
    new Date()
  ];

  const thisYear = [new Date(new Date().getFullYear(), 0, 1), new Date()];

  const lastYear = [
    new Date(new Date().getFullYear() - 1, 0, 1),
    new Date(new Date().getFullYear() - 1, 11, 31)
  ];

  return (
    <div className="w-full flex flex-col md:flex-row">
      <Calendar
        className={`${presets ? 'hasPresets' : ''}`}
        value={dateRange}
        onChange={handleDateChange}
        selectionMode="range"
        readOnlyInput
        dateFormat="d. M yy"
        locale="no"
        showIcon
        minDate={minDate}
        maxDate={maxDate}
        {...props}
      />
      {presets && (
        <Select
          value={selectedOption}
          onValueChange={handleSelectChange}
          enableClear={false}
          placeholder="Velg periode"
          color="blue"
          className="min-w-[11rem]"
        >
          <SelectItem value="lastOneMonth"> Siste 30 dager</SelectItem>
          <SelectItem value="lastThreeMonths">Siste 90 dager</SelectItem>
          <SelectItem value="lastSixMonths"> Siste 6 måneder</SelectItem>
          <SelectItem value="lastTwelveMonths">Siste 12 måneder</SelectItem>
          <SelectItem value="thisYear">Hittil i år</SelectItem>
          <SelectItem value="lastYear">Hele forrige år</SelectItem>
        </Select>
      )}
    </div>
  );
};

export default DateRangePicker;
