// Format hours amount to string with space for every thousand, and with comma instead of dot and add leading zero if needed
const formatTimeAmount = (number) => {
  const formattedNumber = number
    .toLocaleString('en-US', { minimumFractionDigits: 2 })
    .replace(/,/g, ' ')
    .replace('.', ',');
  if (formattedNumber.startsWith(',')) {
    return `0${formattedNumber}`;
  } else {
    return formattedNumber;
  }
};

export default formatTimeAmount;
