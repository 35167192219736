// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tbody .tremor-TableRow-row:nth-child(odd) {
  background-color: #fff;
}

tbody .tremor-TableRow-row:nth-child(even) {
  background-color: #f8fafc;
}

tbody .sick-leave.tremor-TableRow-row:hover {
  background-color: #f1f5f9;
}
`, "",{"version":3,"sources":["webpack://./dev/js/components/Tables/Table.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["tbody .tremor-TableRow-row:nth-child(odd) {\n  background-color: #fff;\n}\n\ntbody .tremor-TableRow-row:nth-child(even) {\n  background-color: #f8fafc;\n}\n\ntbody .sick-leave.tremor-TableRow-row:hover {\n  background-color: #f1f5f9;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
