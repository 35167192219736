export const mapItemList = (itemList, name, checked, type, isPersonTable) => {
  if (name === 'all') {
    return itemList.map((item) => ({ ...item, isChecked: checked }));
  }

  if (type === 'tilLeder') {
    return itemList.map((item) => {
      const [usid, dato] = name.split('_');
      const isChecked = item.usid === Number(usid) && item.dato === dato;
      if (isChecked) {
        return { ...item, isChecked: checked };
      } else return { ...item };
    });
  } else {
    return itemList.map((item) => {
      const isChecked = isPersonTable
        ? item.dato === name
        : item.usid === Number(name);
      if (isChecked) {
        return { ...item, isChecked: checked };
      } else return { ...item };
    });
  }
};

export const getCheckedItems = (itemList, type, isPersonTable) => {
  return itemList.reduce((arr, item) => {
    const { usid, dato, isChecked } = item;

    if (isChecked) {
      if (type === 'tilLeder') {
        arr.push({ usid, dato });
      } else {
        arr.push(isPersonTable ? dato : usid);
      }
    }

    return arr;
  }, []);
};
