import { h } from 'preact'; /** @jsx h */
import { useState } from 'preact/hooks';

import { datoToText } from '../../utils/formatDates';

import TableTimesheet from '../Tables/TableTimesheet';

import {
  Card,
  Flex,
  MultiSelect,
  MultiSelectItem,
  TabPanel,
  Text,
  Title
} from '@tremor/react';

import './TabPanel.css';

const UserHoursTimesheetPanel = ({ data, arrayName }) => {
  const [selectedTimeType, setSelectedTimeType] = useState([]);

  const dataArray = data && data[arrayName] ? data[arrayName] : [];

  return (
    <TabPanel>
      <Card className="mt-8 mb-4">
        <Flex
          className="space-x-0.5 mb-4 flex flex-col sm:flex-row sm:items-center sm:justify-between"
          justifyContent="between"
          alignItems="center"
        >
          <Flex
            flexDirection="col"
            className="space-x-0.5"
            justifyContent="start"
            alignItems="start"
          >
            <Flex
              className="space-x-0.5"
              justifyContent="start"
              alignItems="center"
            >
              {data.type ? (
                <Title>
                  {data.type === 'E' ? 'Egenmelding' : 'Sykemelding'}{' '}
                  timedetaljer
                </Title>
              ) : (
                <Title>Timer registrert</Title>
              )}
              <div className="tooltip">
                <span className="material-symbols-outlined info-icon">
                  info
                </span>
                {data.type ? (
                  <span className="tooltiptext">
                    Alle timer registrert for valgt fraværsperiode. Aktive og
                    passive syketimer er inkludert.
                  </span>
                ) : (
                  <span className="tooltiptext">
                    Alle timer registrert for valgt periode.
                  </span>
                )}
              </div>
            </Flex>
            {data.fra && data.til && (
              <Text>
                {datoToText(data.fra)} - {datoToText(data.til)}
              </Text>
            )}
          </Flex>
          {dataArray && dataArray.length > 0 && (
            <MultiSelect
              className="max-w-full mt-2 sm:mt-0 sm:max-w-xs"
              onValueChange={setSelectedTimeType}
              value={selectedTimeType || []}
              placeholder="Velg type time"
            >
              {dataArray
                .reduce((unique, item) => {
                  return unique.findIndex(
                    (u) => u.lkode_navn === item.lkode_navn
                  ) < 0
                    ? [...unique, item]
                    : unique;
                }, [])
                .sort((a, b) => a.lkode_navn.localeCompare(b.lkode_navn))
                .map((item) => {
                  return (
                    <MultiSelectItem
                      value={item.lkode_navn}
                      key={item.lkode_navn}
                    >
                      {item.lkode_navn}
                    </MultiSelectItem>
                  );
                })}
            </MultiSelect>
          )}
        </Flex>
        <TableTimesheet
          data={dataArray}
          selectedTimeType={selectedTimeType}
          arrayName={arrayName}
        />
      </Card>
    </TabPanel>
  );
};

export default UserHoursTimesheetPanel;
