function parseServerDato(datoStreng) {
  if (datoStreng === undefined) {
    return null;
  }

  const str = datoStreng.toString();

  return {
    year: str.substr(0, 4),
    month: parseInt(str.substr(4, 2)) - 1,
    day: str.substr(6, 2)
  };
}

export function lagDatoObjekt(datoStr) {
  const { year, month, day } = parseServerDato(datoStr);

  return new Date(year, month, day);
}

// 20220220
export const leggTilDager = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);

  return result;
};
