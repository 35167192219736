import { parseISO, isValid } from 'date-fns';

const fetchData = async (action, options = '') => {
  const res = await fetch('/api/admTimereg.php?action=' + action, {
    method: 'post',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(options)
  });

  const json = await res.json();

  return json;
};

export const timeTypePromise = () => {
  return fetchData('lonnskode');
};

export const statusType = [
  {
    id: 1,
    beskrivelse: 'Plan'
  },
  {
    id: 2,
    beskrivelse: 'Reg'
  },
  {
    id: 3,
    beskrivelse: 'Sendt'
  },
  {
    id: 4,
    beskrivelse: 'Godkj-TL'
  },
  {
    id: 5,
    beskrivelse: 'Godkj-LD'
  }
];
const MONTHS = [
  'Januar',
  'Februar',
  'Mars',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Desember'
];
const DAYS = ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'];
export const monthYear = (pmnd) => {
  if (!pmnd) {
    return 'Ukjent';
  }

  const yearMonth = pmnd.toString();

  if (yearMonth.length === 2) {
    return `20${yearMonth}`;
  }

  const year = yearMonth.substring(0, 4);
  const month = parseInt(yearMonth.substring(4, 6));

  if (isNaN(month)) {
    return year;
  } else {
    return `${MONTHS[month - 1]} ${year}`;
  }
};

export const manedAr = (dateStr) => {
  if (!dateStr || typeof dateStr !== 'string') {
    return 'Ukjent';
  }

  const [ar, maned] = dateStr.split('-');

  if (!maned) {
    if (ar.length === 2) {
      return `20${ar}`;
    }

    return ar;
  }

  return `${MONTHS[maned - 1]} ${ar}`;
};

export const datoToText = (dato) => {
  // dato eksempel int 20220105
  if (!dato) {
    return 'Ukjent';
  }
  const dateString = dato.toString();

  const monthAndYear = monthYear(dateString);

  if (dateString.length === 8) {
    const parseDato = parseISO(dateString);

    if (!isValid(parseDato)) {
      return 'Ugyldig dato';
    }

    const day = parseInt(dateString.substring(6));
    return `${DAYS[parseDato.getDay()]} ${day}. ${monthAndYear}`;
  } else {
    return monthAndYear;
  }
};

export const regToText = (registrert) => {
  // Eksempel registrert = "2022-01-11 19:33:03"
  if (!registrert) {
    return 'Ukjent';
  }
  const [yearMonthDay, time] = registrert.split(' ');

  const collapse = yearMonthDay.split('-').join('');
  const dato = datoToText(collapse);
  const timeStr = time || 'ukjent tidspunkt';

  return `${dato} ${timeStr}`;
};

export const parseManed = (maned) => maned.split('-').join('');
