import { h } from 'preact'; /** @jsx h */
import { Input } from '../components/input';

const leggTilBindestrek = (datoStr) =>
  datoStr.toString().replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');

const fjernBindestrek = (datoStr) => datoStr.split('-').join('');

const FraOgTilDato = ({ fraDato, tilDato, disabled, setFra, setTil }) => {
  const onChangeFra = (e) => {
    setFra(fjernBindestrek(e.target.value));
  };

  const onChangeTil = (e) => {
    setTil(fjernBindestrek(e.target.value));
  };

  return (
    <div className="flex">
      <Input
        id="sykemelding_fra"
        type="date"
        label="Fra"
        value={leggTilBindestrek(fraDato)}
        disabled={disabled}
        onChange={onChangeFra}
      />
      <Input
        id="sykemelding_til"
        type="date"
        label="Til"
        value={leggTilBindestrek(tilDato)}
        onChange={onChangeTil}
        disabled={disabled}
      />
    </div>
  );
};

export default FraOgTilDato;
