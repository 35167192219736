import { Fragment, h } from 'preact'; /** @jsx h */

import DateRangePicker from '../DateRangePicker/DateRangePicker';

import { Flex, Metric, Subtitle } from '@tremor/react';

const PageHeader = ({
  isFront,
  toggleRow,
  openRow,
  setOpenRow = false,
  setIndex,
  dateRange,
  setDateRange,
  backLink,
  backBtnText,
  subTitle,
  metricTitle
}) => {
  return (
    <Fragment>
      {!isFront ? (
        <Flex className="flex-col items-start lg:flex-row lg:items-center lg:justify-between">
          {openRow ? (
            <div
              className="w-40 mb-4 flex row items-center hover:text-primary cursor-pointer"
              onClick={() => {
                toggleRow(null);
                setIndex(false);
              }}
            >
              <span className="material-symbols-outlined text-icon-md">
                arrow_back
              </span>
              <span>Tilbake</span>
            </div>
          ) : (
            <div className="w-40 mb-4 hover:text-primary">
              <a href={backLink} className="flex row items-center">
                <span className="material-symbols-outlined text-icon-md max-w-6 whitespace-nowrap overflow-hidden">
                  arrow_back
                </span>
                <span className="whitespace-nowrap">{backBtnText}</span>
              </a>
            </div>
          )}
          {dateRange && (
            <div className="mb-4 hidden lg:block">
              <DateRangePicker
                dateRange={dateRange}
                setDateRange={setDateRange}
                setOpenRow={setOpenRow}
              />
            </div>
          )}
        </Flex>
      ) : (
        <Flex className="flex-col items-start lg:flex-row lg:items-center lg:justify-between">
          {openRow && (
            <div
              className="w-40 mb-4 flex row items-center hover:text-primary cursor-pointer"
              onClick={() => {
                toggleRow(null);
                setIndex(1);
              }}
            >
              <span className="material-symbols-outlined text-icon-md">
                arrow_back
              </span>
              <span>Tilbake</span>
            </div>
          )}
        </Flex>
      )}
      <Flex
        flexDirection="col md-row"
        alignItems="end"
        justifyContent="between"
        className="mb-4"
      >
        <Flex flexDirection="col" alignItems="start" justifyContent="between">
          <Subtitle>{subTitle}</Subtitle>
          <Metric>{metricTitle}</Metric>
        </Flex>
        {isFront && dateRange && (
          <div className="mb-4 hidden lg:block">
            <DateRangePicker
              dateRange={dateRange}
              setDateRange={setDateRange}
              setOpenRow={setOpenRow}
            />
          </div>
        )}
      </Flex>
      {dateRange && (
        <div className="block w-full mb-4 lg:hidden">
          <DateRangePicker
            dateRange={dateRange}
            setDateRange={setDateRange}
            setOpenRow={setOpenRow}
          />
        </div>
      )}
    </Fragment>
  );
};

export default PageHeader;
