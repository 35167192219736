import { h } from 'preact'; /** @jsx h */
import { datoToText } from './utils';

const ListeFravaer = ({ arr, emptyMessage, handleClickSjekk }) => {
  if (arr.length === 0) {
    return (
      <table className="td-150">
        <tr className="tr td-150">
          <td>{emptyMessage}</td>
        </tr>
      </table>
    );
  }

  return (
    <table className="td-150">
      <thead className="bt b--black">
        <tr className="tr">
          <td></td>
          <td>Navn</td>
          <td>Fra</td>
          <td>Til</td>
          <td>Vis</td>
        </tr>
      </thead>
      <tbody>
        {arr.map(({ usid, navn, fra, til }, index) => (
          <tr key={index} className="tr td-150">
            <td className="">
              <img
                className="img-20 avatar mb3"
                src={`/images/brukere/${usid}.jpg`}
                alt=""
              />
            </td>
            <td className="ph2">{navn}</td>
            <td className="ph2">{datoToText(fra)}</td>
            <td className="ph2">{datoToText(til)}</td>
            <td className="ph2">
              <button
                type="button"
                onClick={(e) => handleClickSjekk(usid, fra)}
              >
                Vis
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ListeFravaer;
