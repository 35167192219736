// Format currency amount to string with space for every thousand and comma instead of dot and add kr at the end
const formatCurrencyAmount = (number) => {
  // to string and space for every thousand
  const str = number
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    .replace('.', ',');

  const parts = str.split(',');
  if (parts.length === 1) {
    return `${str},00 kr`;
  } else {
    if (parts[1].length === 1) {
      return `${str}0 kr`;
    } else {
      return str + ' kr';
    }
  }
};

export default formatCurrencyAmount;
