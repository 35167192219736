// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-icon {
  margin-left: 0.5rem;
  font-size: 22px;
  cursor: pointer;
  vertical-align: middle;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  max-width: 400px;
  min-width: 300px;
  background-color: #000000cc;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 0.75rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.6;

  /* Position the tooltip */
  position: absolute;
  bottom: 2rem;
  z-index: 1;
  transform: translateX(-50%);
}

/* Adjust tooltip for smaller screens */
@media (max-width: 768px) {
  .tooltip .tooltiptext {
    position: fixed;
    max-width: 92vw;
    width: 92vw;
    left: 50%;
    bottom: 1rem;
    transform: translateX(-50%);
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
`, "",{"version":3,"sources":["webpack://./dev/js/components/TabPanels/TabPanel.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,2BAA2B;EAC3B,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;;EAEhB,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,2BAA2B;AAC7B;;AAEA,uCAAuC;AACvC;EACE;IACE,eAAe;IACf,eAAe;IACf,WAAW;IACX,SAAS;IACT,YAAY;IACZ,2BAA2B;EAC7B;AACF;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".info-icon {\n  margin-left: 0.5rem;\n  font-size: 22px;\n  cursor: pointer;\n  vertical-align: middle;\n}\n\n.tooltip {\n  position: relative;\n}\n\n.tooltip .tooltiptext {\n  visibility: hidden;\n  max-width: 400px;\n  min-width: 300px;\n  background-color: #000000cc;\n  color: #fff;\n  text-align: center;\n  border-radius: 4px;\n  padding: 0.75rem 0.5rem;\n  font-size: 0.875rem;\n  line-height: 1.6;\n\n  /* Position the tooltip */\n  position: absolute;\n  bottom: 2rem;\n  z-index: 1;\n  transform: translateX(-50%);\n}\n\n/* Adjust tooltip for smaller screens */\n@media (max-width: 768px) {\n  .tooltip .tooltiptext {\n    position: fixed;\n    max-width: 92vw;\n    width: 92vw;\n    left: 50%;\n    bottom: 1rem;\n    transform: translateX(-50%);\n  }\n}\n\n.tooltip:hover .tooltiptext {\n  visibility: visible;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
