// diverse fetch funksjoner mot API-et
// se dokumentasjon: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch

export const fetchApi = async (uri, method, body) => {
  let response;

  try {
    response = await fetch(uri, {
      method,
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    if (!response.ok) {
      throw new Error('response not OK');
    }
  } catch (error) {
    // logg at feil inntraff
    // eslint-disable-next-line no-console
    console.log('Fetch error', uri, error);

    return { error: true };
  }

  try {
    return await response.json();
  } catch (error) {
    // logg at feil inntraff
    // eslint-disable-next-line no-console
    console.log('Fetch JSON error', uri, error);

    return { error: true };
  }
};

export const get = (uri) => fetchApi(uri, 'get');

export const post = (uri, data) => fetchApi(uri, 'post', data);
