import { Fragment, h } from 'preact'; /** @jsx h */

import formatCurrencyAmount from '../../utils/formatCurrencyAmount';
import { datoToText } from '../../utils/formatDates';
import formatTimeAmount from '../../utils/formatTimeAmount';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow
} from '@tremor/react';

import './Table.css';

const TableTimesheet = ({ data, selectedTimeType, arrayName }) => {
  const sykeTimer = arrayName === 'syke_timer';

  // Declare variables for total timer and beløp
  let timerTotal = 0;
  let belopTotal = 0;

  return (
    <Fragment>
      <Table
        className={`max-h-[calc(100vh-462px${sykeTimer ? '-32px' : ''})] min-h-[calc(100vh-462px${sykeTimer ? '-32px' : ''})] no-scrollbar`}
      >
        <TableHead className="sticky top-0 bg-white">
          <TableRow>
            <TableHeaderCell className="py-0 px-2">Dato</TableHeaderCell>
            <TableHeaderCell className="py-0 px-2">Type time</TableHeaderCell>
            <TableHeaderCell className="py-0 px-2 text-right">
              Timesats
            </TableHeaderCell>
            <TableHeaderCell className="py-0 px-2 text-right">
              Antall timer
            </TableHeaderCell>
            <TableHeaderCell className="py-0 px-2 text-right">
              Beløp
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.length > 0 ? (
            data
              .filter(
                (item) =>
                  selectedTimeType.includes(item.lkode_navn) ||
                  selectedTimeType.length === 0
              )
              .map((item) => {
                const dato = datoToText(item.dato);
                const belop = item.sats * parseFloat(item.antall);

                timerTotal += parseFloat(item.antall);
                belopTotal += belop;

                const sats = formatCurrencyAmount(parseFloat(item.sats));
                const antall = formatTimeAmount(item.antall);

                const belopString = formatCurrencyAmount(
                  item.sats * parseFloat(item.antall)
                );

                return (
                  <TableRow className="" key={`${dato}-${belopString}`}>
                    <TableCell className="py-2 px-2 leading-4 w-[30%]">
                      {dato}
                    </TableCell>
                    <TableCell className="py-2 px-2 leading-4 w-[30%]">
                      {item.lkode_navn}
                    </TableCell>
                    <TableCell className="py-2 px-2 leading-4 text-right w-[13.3%]">
                      {sats}
                    </TableCell>
                    <TableCell className="py-2 px-2 leading-4 text-right w-[13.3%]">
                      {antall}
                    </TableCell>
                    <TableCell className="py-2 px-2 leading-4 text-right w-[13.3%]">
                      {belopString}
                    </TableCell>
                  </TableRow>
                );
              })
          ) : (
            <TableRow>
              <TableCell
                colSpan={6}
                className="text-center py-2 px-2 leading-4 h-[calc(100vh-510px-41px)]"
              >
                Ingen timedetaljer funnet i valgt periode
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Table>
        <TableBody>
          <TableRow className="">
            <TableCell className="py-2 px-2 leading-6 w-[30%]">
              <b>Totalt</b>
            </TableCell>
            <TableCell className="py-2 px-2 leading-6 w-[30%]" />
            <TableCell className="py-2 px-2 leading-6 w-[13.3%]" />
            <TableCell className="py-2 px-2 leading-6 text-right w-[13.3%]">
              <b>{formatTimeAmount(timerTotal)} timer</b>
            </TableCell>
            <TableCell className="py-2 px-2 leading-6 text-right w-[13.3%]">
              <b>{formatCurrencyAmount(belopTotal)}</b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Fragment>
  );
};

export default TableTimesheet;
