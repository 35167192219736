import { h } from 'preact'; /** @jsx h */
import { useEffect, useState } from 'preact/hooks';
import { Error, Input, Textarea } from './input.js';
import { datoToText, timeTypePromise } from './utils.js';

const EditForm = (props) => {
  const [timeType, setTimeType] = useState([]);
  useEffect(async () => {
    const a = await timeTypePromise();

    setTimeType(a.timetype.slice(0, 5));
  }, []);

  const { itemData, handleCancel } = props;

  const [endreData, setEndreData] = useState({});
  const [logg, setLogg] = useState();
  const [showError, setShowError] = useState(false);
  const [errorTekst, setErrorTekst] = useState();

  const [submitType, setSubmitType] = useState('');

  let beskrivelse = '';
  const beskIndex = itemData.findIndex((x) => x.beskriv !== '');
  if (beskIndex >= 0) {
    beskrivelse = itemData[beskIndex].beskriv;
  }

  const handleEdit = (e) => {
    const newType = e.target.id;
    const newValue = e.target.value;

    if (newType === 'logg') {
      setLogg(newValue);
    } else {
      const indexTemp = itemData.findIndex((x) => x.lkode === Number(newType));
      const oldValue = indexTemp >= 0 ? itemData[indexTemp].time : 0;

      if (oldValue !== newValue) {
        setEndreData({ ...endreData, [newType]: newValue });
      } else {
        delete endreData[newType];
      }
    }
  };

  const handleSubmit = (e) => {
    setSubmitType(e.target.value);
  };

  const sendTilAPI = async (action, data) => {
    const resp = await fetch('/api/admTimereg.php?action=' + action, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const json = await resp.json();

    if (json.success) {
      window.location.reload();
    } else {
      setErrorTekst('Feil fra server: ' + json.error_msg);
      setShowError(true);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const gamleData = {};
    itemData.forEach((x) => (gamleData[x.lkode] = x.time));

    if (submitType === 'edit') {
      const data = {
        dato: itemData[0].dato,
        usid: itemData[0].usid,
        gamle: gamleData,
        endre: endreData,
        logg
      };

      if (
        Object.keys(endreData).length === 0 &&
        endreData.constructor === Object
      ) {
        setErrorTekst('Har ikke noe endring...');
        setShowError(true);
      } else {
        setShowError(false);

        sendTilAPI(submitType, data);
      }
    }

    if (submitType === 'slett') {
      const data = {
        dato: itemData[0].dato,
        usid: itemData[0].usid,
        gamle: gamleData,
        logg
      };

      sendTilAPI(submitType, data);
    }
  };

  return (
    <div className="modal-box">
      <div className="modal-content">
        <form onSubmit={onSubmit}>
          <h3 className="mb3">
            {itemData[0].navn}, {datoToText(itemData[0].dato)}
          </h3>
          <h4 className="mb3">Beskrivelse: {beskrivelse || '(tomt)'}</h4>
          {timeType.map((x) => {
            const index = itemData.findIndex((y) => y.lkode === x.lkode);
            const timeValue = index >= 0 ? itemData[index].time : 0;

            return (
              <Input
                key={x.lkode}
                id={x.lkode}
                type="number"
                label={x.beskrivelse}
                value={timeValue}
                onChange={handleEdit}
                step=".25"
              ></Input>
            );
          })}
          <Textarea
            label="Logg"
            name="logg"
            rows="6"
            onChange={handleEdit}
          ></Textarea>

          {showError && <Error tekst={errorTekst}></Error>}

          <div className="flex justify-between mt4">
            <button
              className="mt3 mb3 pa2 ph3 bg-yellow white db"
              value="slett"
              type="submit"
              onClick={handleSubmit}
            >
              Slett registrering
            </button>
            <button
              className="mt3 mb3 pa2 ph3 bg-secondary white db"
              onClick={handleCancel}
            >
              Avbryt
            </button>
            <button
              className="mt3 mb3 pa2 ph3 bg-primary white db"
              value="edit"
              type="submit"
              onClick={handleSubmit}
            >
              Lagre endring
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditForm;
