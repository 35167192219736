import { h } from 'preact'; /** @jsx h */
import { useEffect, useState } from 'preact/hooks';
import { Error, Textarea } from '../components/input';
import FraOgTilDato from './FraOgTilDato';
import RedigerFravaerDager from './RedigerFravaerDager';
import { lagDatoObjekt, leggTilDager } from './datoUtils';
import { fetchApi } from '../fetch';

const RedigerFravaerMelding = ({ person, setEdit, gamle, type, doneCb }) => {
  const [fraMelding, setFraMelding] = useState(person.fra);
  const [tilMelding, setTilMelding] = useState(person.til);
  const [error, setError] = useState(false);
  const [endre, setEndre] = useState({});
  const [logg, setLogg] = useState('');

  let isDisabled = false;

  useEffect(async () => {
    if (fraMelding && tilMelding) {
      getPersonPlan();
    }
  }, [fraMelding, tilMelding, person.navn]);

  const slettMelding = async () => {
    const res = await fetchApi('/api/admSyk.php?action=slett', 'post', {
      usid: parseInt(person.usid),
      fra: parseInt(person.fra),
      til: parseInt(person.til),
      logg,
      gamle,
      type
    });
    if (res.error) {
      setError(true);
      return;
    }

    if (res.error_msg) {
      setError(res.error_msg);
      return;
    }
    if (res.success) {
      doneCb();
    }
  };

  const getPersonPlan = async () => {
    const res = await fetchApi(
      '/api/admSyk.php?action=get_syke_dager',
      'post',
      {
        usid: Number(person.usid),
        fra: Number(fraMelding),
        til: Number(tilMelding),
        orig_fra: Number(person.fra),
        orig_til: Number(person.til),
        type
      }
    );
    if (res.error) {
      setError(true);
      return;
    }

    if (res.error_msg) {
      setError(res.error_msg);
      return;
    }
    if (res.success) {
      setEndre(res.timer);
      setError('');
    }
  };
  const redigerMelding = async () => {
    const sendEndre = { ...endre };
    Object.keys(sendEndre).map((dato) => delete sendEndre[dato].status);
    const res = await fetchApi('/api/admSyk.php?action=rediger', 'post', {
      usid: Number(person.usid),
      fra: Number(fraMelding),
      til: Number(tilMelding),
      orig_fra: Number(person.fra),
      orig_til: Number(person.til),
      logg,
      gamle,
      endre: sendEndre,
      type
    });

    if (res.error) {
      setError(true);
      return;
    }

    if (res.error_msg) {
      setError(res.error_msg);
      return;
    }

    if (res.success) {
      doneCb(res);
    }
  };

  const onClickSlett = () => {
    if (!logg) {
      setError('Mangler tekst i logg');
    } else {
      slettMelding();
    }
  };

  const onChangeEndre = (e, lkode, datestr) => {
    const nyE = { ...endre };
    nyE[datestr] = { ...nyE[datestr], [lkode]: parseFloat(e.target.value) };

    setEndre(nyE);
  };

  isDisabled = Object.keys(endre).some((datoObjekt) => {
    return endre[datoObjekt].status > 1;
  });

  if (endre.length === 0) {
    isDisabled = true;
    setError('Meldingsperiode mangler dager!');
  }

  const remove = (dato, e) => {
    e.preventDefault();
    if (Object.keys(endre).length > 1) {
      const nyE = { ...endre };
      delete nyE[dato];
      setEndre(nyE);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // egenmelding
    if (type === 'E') {
      const fraDatoObjekt = lagDatoObjekt(fraMelding);
      const tilDatoObjekt = lagDatoObjekt(tilMelding);
      const tilDatoObjektMs = tilDatoObjekt.getTime();

      const maksTilDato = leggTilDager(fraDatoObjekt, 2);
      const maksTilDatoMs = maksTilDato.getTime();

      if (fraDatoObjekt.getTime() > tilDatoObjektMs) {
        setError(`Feil til dato!`);
      } else if (maksTilDatoMs && tilDatoObjektMs > maksTilDatoMs) {
        setError(`Maks til dato ${maksTilDato.toLocaleDateString('en-GB')}`);
      } else {
        redigerMelding();
      }
    } else {
      redigerMelding();
    }
  };

  return (
    <div id="rediger_fravaeremelding" className="mt4">
      {error && <Error tekst={`Feilmelding: ${error}`}></Error>}
      <form method="post" onSubmit={onSubmit}>
        <FraOgTilDato
          fraDato={fraMelding}
          tilDato={tilMelding}
          disabled={person.til_frav}
          setFra={setFraMelding}
          setTil={setTilMelding}
          type={type}
        />
        <RedigerFravaerDager
          fravaersDager={endre}
          fjernDato={remove}
          onChangeEndre={onChangeEndre}
          fjernDisabled={isDisabled}
          type={type}
        />
        <div>
          <Textarea
            label="Logg"
            name="logg"
            rows="6"
            onChange={(e) => setLogg(e.target.value)}
          ></Textarea>
          <div className="flex">
            {!person.til_frav.length > 0 && (
              <button
                className="mt3 mb3 ml3 pa2 ph3 bg-primary white db"
                disabled={person.til_frav}
                name="slett"
                type="button"
                onClick={onClickSlett}
              >
                Slett melding
              </button>
            )}
            <button
              className="mt3 mb3 ml3 pa2 ph3 bg-green white db "
              disabled={isDisabled}
              name="lagre"
            >
              Lagre
            </button>
            <button
              className="mt3 mb3 ml3 pa2 ph3 bg-secondary white db "
              onClick={() => setEdit(false)}
              type="button"
            >
              Avbryt
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RedigerFravaerMelding;
